/*
 * @Author       : NaN 353362604@qq.com
 * @Date         : 2023-06-05 17:31:19
 * @LastEditTime : 2023-06-06 09:22:58
 * @LastEditors  : NaN 353362604@qq.com
 * @FilePath     : \camera-video\src\App.jsx
 * @Description  : 
 */
// import { EZUIKitPlayer } from 'ezuikit-js'
import { useBoolean, useSize } from "ahooks"
import { Button } from 'antd'
import { useEffect, useRef, useState } from "react"


const App = () => {
    const VideoBox = useRef()
    const size = useSize(VideoBox)
    const [player, setPlayer] = useState()
    const [Status, { setTrue, setFalse }] = useBoolean()

    useEffect(() => {
        if (size) {
            const { search } = window.location
            const newStr = search.slice(1).split('&')
            const data = newStr.reduce((acc, item) => {
                const [key, value] = item.split('=')
                return { ...acc, [key]: value }
            }, {})
            const { width, height } = size

            const { token, deviceSerial, channelNo } = data

            const player = new window.EZUIKit.EZUIKitPlayer({
                id: 'CameraVideo', // 视频容器ID
                accessToken: token,
                url: `ezopen://open.ys7.com/${deviceSerial}/${channelNo}.live`,
                audio: 1,
                width,
                height,
                plugin: ["talk"],
            })
            console.log('`````````````````````````````````', player)

            player.play();
            setPlayer(player)
        }
    }, [size])

    const Start = () => {
        setTrue()
        // console.log(player)
        player.startTalk()
    }
    const End = () => {
        setFalse()
        player.stopTalk()
    }

    return (
        <div className="CameraVideo" ref={VideoBox}>
            <div id="CameraVideo" />
            <div className='Options'>
                {Status ?
                    <Button ghost danger onClick={End}>结束对讲</Button> :
                    <Button ghost onClick={Start} >开始对讲</Button>
                }
            </div>
        </div>
    );
};

export default App;
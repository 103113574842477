/*
 * @Author       : NaN 353362604@qq.com
 * @Date         : 2023-06-05 17:26:42
 * @LastEditTime : 2023-06-06 08:53:38
 * @LastEditors  : NaN 353362604@qq.com
 * @FilePath     : \camera-video\src\index.js
 * @Description  : 
 */
import { createRoot } from 'react-dom/client'
import App from './App'
import "./index.scss"

createRoot(document.getElementById('root')).render(<App />)

